<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">나의 학습방</p>
      </div>
    </div>

    <section class="py-14 pc:py-[140px] pc-con-wrapper1">
      <div v-for="item in myClassInfoDetail.setMyClassInfo.myClassInfoList" :key="item">
        <p class="text-xl font-bold text-center text-neutral-950 mb-7 pc:text-4xl pc:mb-11">
          {{ item.txtLecture }} - {{ item.courseName }}
        </p>
        <div class="border border-solid mb-14 pc:mb-[140px] border-neutral-200 rounded-xl pc:rounded-2xl">
          <ul class="pc:max-w-[850px] pc:mx-auto py-7 pc:py-11 px-5 pc:px-0 flex flex-col gap-5">
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">기간</p>
              <p class="pc:text-xl">{{ item.sdate }} ~ {{ item.edate }}</p>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">시간</p>
              <p class="pc:text-xl">{{ item.stime }} ~ {{ item.etime }}</p>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">스케줄</p>
              <p class="pc:text-xl">주{{ item.lecDayCnt }}회 {{ item.lecMin }}분 ({{ item.lecDayTxt }})</p>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">강사</p>
              <p class="pc:text-xl">{{ item.tutorEname }}</p>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">교재</p>
              <p class="pc:text-xl">{{ item.bookName }}</p>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">진척도</p>
              <div class="flex items-center gap-3 pc:gap-4 grow">
                <div class="relative w-full h-4 rounded-lg pc:h-5 bg-neutral-200">
                  <div
                    class="absolute top-0 left-0 h-full rounded-lg bg-violet-500"
                    :style="{
                      width: item.perProgress + '%',
                    }"
                  ></div>
                </div>
                <p class="flex-none text-sm font-bold pc:text-xl text-neutral-950 w-36">
                  {{ item.perProgress }}%
                  <span class="font-medium">({{ item.cntProgress }}/{{ item.cntSchedule }})</span>
                </p>
              </div>
            </li>
            <li class="flex items-center gap-7 pc:gap-12">
              <p class="flex-none w-10 text-sm font-bold pc:text-xl text-neutral-950 pc:w-14">출석률</p>
              <div class="flex items-center gap-3 pc:gap-4 grow">
                <div class="relative w-full h-4 rounded-lg pc:h-5 bg-neutral-200">
                  <div
                    class="absolute top-0 left-0 h-full rounded-lg bg-violet-500"
                    :style="{
                      width: item.perAttendance + '%',
                    }"
                  ></div>
                </div>
                <p class="flex-none text-sm font-bold pc:text-xl text-neutral-950 w-36">
                  {{ item.perAttendance }}%
                  <span class="font-medium">({{ item.cntAttendance }}/{{ item.cntProgress }})</span>
                </p>
              </div>
            </li>
          </ul>
          <ul class="grid grid-cols-3 border-t border-solid border-neutral-200">
            <a
              class="py-4 text-sm font-bold text-center border-r border-solid cursor-pointer pc:py-10 border-neutral-200 pc:text-2xl text-neutral-950"
              @click="myClassInfoDetail.baseConvert"
            >
              월별평가표
            </a>
            <!-- <a
                        class="py-4 text-sm font-bold text-center border-r border-solid cursor-pointer pc:py-10 border-neutral-200 pc:text-2xl text-neutral-950"
                        :href="
                            'https://www.carrotenglish.kr/new_mypage/pop/monthly_ev?idxLectureUser=' +
                            item.idxLectureUser
                        "
                        target="_blank"
                    >
                        월별평가표
                    </a> -->
            <li
              class="py-4 text-sm font-bold text-center border-r border-solid cursor-pointer pc:py-10 border-neutral-200 pc:text-2xl text-neutral-950"
              @click="myClassInfoDetail.checkAttendance(item)"
            >
              출석확인증
            </li>
            <li
              class="py-4 text-sm font-bold text-center cursor-pointer pc:py-10 pc:text-2xl text-neutral-950"
              @click="myClassInfoDetail.courseAttendanceCertificate(item)"
            >
              수강확인증
            </li>
          </ul>
        </div>
      </div>

      <!-- 학습내역 없는 경우 노출 -->
      <div v-if="myClassInfoDetail.setMyClassInfo.myClassInfoList.length === 0" class="flex flex-col items-center gap-7">
        <img src="@lifeeduWeb/assets/images/myClass/warn.png" alt="icon" class="w-10" />
        <p class="text-lg font-bold pc:text-xl">진행중인 학습내역이 없습니다.</p>
      </div>
    </section>

    <!-- 학습내역 없는 경우 노출 -->
    <!-- <p
      v-if="myClassInfoDetail.setMyClassInfo.myClassInfoList.length === 0"
      class="max-w-xs py-4 mx-auto mb-16 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:mb-36 pc:py-6 pc:text-xl"
      @click="myClassInfoDetail.baseSchedule"
    >
      나의 강의실 바로가기
    </p> -->

    <p
      class="max-w-xs py-4 mx-auto mb-16 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:mb-36 pc:py-6 pc:text-xl"
      @click="myClassInfoDetail.baseSchedule"
    >
      나의 강의실 바로가기
    </p>
  </div>

  <!-- 출석확인증 팝업 -->
  <div v-if="myClassInfoDetail.attendanceModalOnOff" class="fixed top-0 left-0 w-full h-full overflow-y-auto bg-black/50 z-[2]">
    <div class="absolute w-full h-full" @click="myClassInfoDetail.closeModal"></div>
    <div class="flex items-center justify-center min-h-full p-2">
      <div class="flex flex-col justify-center items-center mx-auto p-2 bg-white z-[1] gap-4 modal-print min-w-[500px]">
        <div class="self-end w-6 cursor-pointer" @click="myClassInfoDetail.closeModal">
          <img src="@lifeeduWeb/assets/images/common/close.png" alt="icon" />
        </div>
        <div class="w-full p-2 rounded-lg">
          <div class="flex flex-col gap-1 mb-5">
            <p class="font-bold text-center">Attendance Verification</p>
            <p class="text-center">출석확인증</p>
          </div>
          <ul class="grid grid-cols-1 mb-5 divide-y divide-solid divide-neutral-500">
            <li class="flex items-center gap-2">
              <div class="flex items-center self-stretch flex-none w-24 p-2 font-bold bg-neutral-50">
                <p>Start Date</p>
              </div>
              <input
                type="date"
                name=""
                id=""
                class="p-4 border border-solid rounded border-neutral-200"
                v-model="myClassInfoDetail.attendanceVerification.info.sdate"
              />
            </li>
            <li class="flex items-center gap-2">
              <div class="flex items-center self-stretch flex-none w-24 p-2 font-bold bg-neutral-50">
                <p>End Date</p>
              </div>
              <input
                type="date"
                name=""
                id=""
                class="p-4 border border-solid rounded border-neutral-200"
                v-model="myClassInfoDetail.attendanceVerification.info.edate"
              />
            </li>
          </ul>
          <div class="flex justify-end mb-5">
            <p class="underline cursor-pointer" @click="myClassInfoDetail.searchDate">search</p>
          </div>
          <ul class="grid grid-cols-1 mb-5 divide-y divide-solid divide-neutral-500">
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강자</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.attendanceVerification.info.kname }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">고객 아이디</p>
              </div>
              <p class="p-2 break-all grow">
                {{ myClassInfoDetail.attendanceVerification.info.uid }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">강사명</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.attendanceVerification.info.tutorEname }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강과정</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.attendanceVerification.info.courseName }}
                주{{ myClassInfoDetail.attendanceVerification.info.lecDayCnt }}회
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강 시작일</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.attendanceVerification.info.sdate }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강 종료일</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.attendanceVerification.info.edate }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">출석률</p>
              </div>
              <p class="p-2 grow">{{ myClassInfoDetail.attendanceVerification.info.perAttendance }}%</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수업시간</p>
              </div>
              <p class="p-2 grow">{{ myClassInfoDetail.attendanceVerification.info.lecMin }}분</p>
            </li>
          </ul>
          <table class="mb-5">
            <thead>
              <tr class="bg-neutral-50">
                <th class="p-2 border-r border-solid border-neutral-500">No.</th>
                <th class="p-2 border-r border-solid border-neutral-500">Date</th>
                <th class="p-2 border-r border-solid border-neutral-500">Present</th>
                <th class="p-2">Absent</th>
              </tr>
            </thead>
            <tbody v-for="item in myClassInfoDetail.filteredAttendanceList" :key="item">
              <tr>
                <td class="p-2 border-r border-solid border-neutral-500">
                  {{ item.rowNum }}
                </td>
                <td class="p-2 border-r border-solid border-neutral-500">
                  {{ item.lecDate }}
                </td>
                <td class="p-2 border-r border-solid border-neutral-500">출석</td>
                <td class="p-2"></td>
              </tr>
            </tbody>
          </table>
          <p class="mb-5 text-center">상기 회원은 위와 같이<br />출석하였음을 증명합니다.</p>
          <p class="mb-10 font-bold text-center">
            {{ myClassInfoDetail.getCurrentDate() }}
          </p>
          <ul class="flex items-center justify-center gap-2 mb-10">
            <li>㈜캐럿솔루션즈 이은경</li>
            <li>
              <img src="@lifeeduWeb/assets/images/common/stamp.gif" alt="icon" class="w-14" />
            </li>
          </ul>
          <div class="flex justify-center" @click="myClassInfoDetail.modalPrint">
            <p class="inline-block text-center underline cursor-pointer">프린트하기</p>
          </div>
        </div>
        <p
          class="w-full p-2 text-xl font-bold text-center text-white rounded-lg cursor-pointer bg-violet-500"
          @click="myClassInfoDetail.closeModal"
        >
          확인
        </p>
      </div>
    </div>
  </div>

  <!-- 수강확인증 팝업 -->
  <div v-if="myClassInfoDetail.sugangModalOnOff" class="fixed top-0 left-0 w-full h-full overflow-y-auto bg-black/50 z-[2]">
    <div class="absolute w-full h-full" @click="myClassInfoDetail.sugangCloseModal"></div>
    <div class="flex items-center justify-center min-h-full p-2">
      <div class="flex flex-col justify-center items-center min-w-[500px] mx-auto p-2 bg-white z-[1] gap-4 modal-print">
        <div class="self-end w-6 cursor-pointer" @click="myClassInfoDetail.sugangCloseModal">
          <img src="@lifeeduWeb/assets/images/common/close.png" alt="icon" />
        </div>
        <div class="w-full p-2 rounded-lg">
          <div class="flex flex-col gap-1 mb-5">
            <p class="font-bold text-center">Enrollment Verification</p>
            <p class="text-center">수강확인증</p>
          </div>
          <ul class="grid grid-cols-1 mb-5 divide-y divide-solid divide-neutral-500">
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강자</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.enrollmentVerification.kname }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강기간</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.enrollmentVerification.sdate }}
                ~
                {{ myClassInfoDetail.enrollmentVerification.edate }}
              </p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">수강과정</p>
              </div>
              <p class="p-2 grow">
                {{ myClassInfoDetail.enrollmentVerification.courseName }}
                주{{ myClassInfoDetail.enrollmentVerification.lecDayCnt }}회
              </p>
            </li>
          </ul>
          <ul class="grid grid-cols-1 mb-5 divide-y divide-solid divide-neutral-500">
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">법인명</p>
              </div>
              <p class="p-2 grow">㈜캐럿솔루션즈</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">대표</p>
              </div>
              <p class="p-2 grow">이은경</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">주소</p>
              </div>
              <p class="p-2 grow">서울특별시 서초구 강남대로 479 신논현타워 6층</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">사업자<br />등록번호</p>
              </div>
              <p class="p-2 grow">357-87-02040</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">업태</p>
              </div>
              <p class="p-2 grow">교육서비스업</p>
            </li>
            <li class="flex items-center">
              <div class="flex items-center self-stretch flex-none w-24 p-2 bg-neutral-50">
                <p class="font-bold">종목</p>
              </div>
              <p class="p-2 grow">외국어교육</p>
            </li>
          </ul>
          <p class="mb-5 text-center">상기 회원은 위와 같은 기간 및 과정으로<br />수강하였음을 증명함.</p>
          <p class="mb-10 font-bold text-center">
            {{ myClassInfoDetail.getCurrentDate() }}
          </p>
          <ul class="flex items-center justify-center gap-2 mb-10">
            <li>㈜캐럿솔루션즈 이은경</li>
            <li>
              <img src="@lifeeduWeb/assets/images/common/stamp.gif" alt="icon" class="w-14" />
            </li>
          </ul>
          <div class="flex justify-center" @click="myClassInfoDetail.modalPrint">
            <p class="inline-block text-center underline cursor-pointer">프린트하기</p>
          </div>
        </div>
        <p
          class="w-full p-2 text-xl font-bold text-center text-white rounded-lg cursor-pointer bg-violet-500"
          @click="myClassInfoDetail.sugangCloseModal"
        >
          확인
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const myClassInfoDetail = reactive({
      setMyClassInfo: {
        myClassInfoList: [],
        idxMember: "",
      },

      attendanceVerification: {
        list: [],
        info: {
          idxLectureUser: "",
          idxMember: "",
          kname: "",
          ename: "",
          uid: "",
          tutorEname: "",
          b2bName: "",
          lecDay: "",
          sdate: "",
          edate: "",
          lecMin: "",
          language: "",
          lecStype: "",
          cntProgress: "",
          cntAttendance: "",
          cntAbsence: "",
          courseName: "",
        },
      },

      enrollmentVerification: {
        kname: "",
        uid: "",
        ename: "",
        sdate: "",
        course_name: "",
        b2b_name: "",
        idx: "",
        lec_stype: "",
        lec_day: "",
        edate: "",
      },

      filteredAttendanceList: [],

      attendanceModalOnOff: false,
      sugangModalOnOff: false,

      modalPrint() {
        const printContents = document.querySelector(".modal-print");
        if (printContents) {
          const originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents.innerHTML;
          window.print();
          document.body.innerHTML = originalContents;
        }
        location.reload(true);
      },

      status() {
        axios.get(`/user/sugang/status`).then(function (res) {
          if (res.data.success === true) {
            myClassInfoDetail.setMyClassInfo.myClassInfoList = res.data.data.list;

            myClassInfoDetail.setMyClassInfo.idxMember = res.data.data.idxMember;
          } else {
            alert(res.data.message);
          }
        });
      },

      searchDate() {
        const sdate = myClassInfoDetail.attendanceVerification.info.sdate;
        const edate = myClassInfoDetail.attendanceVerification.info.edate;
        myClassInfoDetail.filteredAttendanceList = myClassInfoDetail.attendanceVerification.list.filter(
          (item) => item.lecDate >= sdate && item.lecDate <= edate
        );
      },

      checkAttendance(item) {
        myClassInfoDetail.attendanceModalOnOff = true;

        axios
          .get(`/user/sugang/attendance`, {
            params: {
              idxLectureUser: item.idxLectureUser,
              edate: item.edate,
              sdate: item.sdate,
            },
          })
          .then(function (res) {
            if (res.data.success === true) {
              myClassInfoDetail.attendanceVerification = res.data.data;
            } else {
              alert(res.data.message);
            }
            myClassInfoDetail.searchDate();
          });
      },

      courseAttendanceCertificate(item) {
        myClassInfoDetail.sugangModalOnOff = true;

        axios
          .get(`/user/sugang/certificate`, {
            params: {
              idxLectureUser: item.idxLectureUser,
            },
          })
          .then(function (res) {
            if (res.data.success === true) {
              myClassInfoDetail.enrollmentVerification = res.data.data;
            } else {
              alert(res.data.message);
            }
          });
      },

      closeModal() {
        myClassInfoDetail.attendanceModalOnOff = false;
      },
      sugangCloseModal() {
        myClassInfoDetail.sugangModalOnOff = false;
      },

      getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        return `${year}년 ${month}월 ${day}일`;
      },

      baseConvert: (number, frombase, tobase) => {
        const idxMember = myClassInfoDetail.setMyClassInfo.idxMember;

        let carrotEnglishApi = "https://carrotenglish.kr/lnk";

        let dateObj = new Date();
        let month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();
        let year = dateObj.getUTCFullYear();

        let numberValue = parseInt(number + "", frombase | 0);

        if (tobase < 2 || tobase > 36) {
          throw new Error("Base must be between 2 and 36");
        }

        let result;
        try {
          result = numberValue.toString(tobase | 0);
        } catch (error) {
          result = "error";
        }

        let link = `${carrotEnglishApi}/D${Math.round(year * 10000 + month * 100 + day)
          .toString(30)
          .toString(tobase | 0)}z${(202 + 10000).toString(30).toString(tobase | 0)}z${(parseInt(idxMember) + 10000)
          .toString(30)
          .toString(tobase | 0)}`;

        window.open(link, "_blank");
        return result;
      },

      baseSchedule: (number, frombase, tobase) => {
        const idxMember = myClassInfoDetail.setMyClassInfo.idxMember;

        let carrotEnglishApi = "https://carrotenglish.com/lnk";

        let dateObj = new Date();
        let month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();
        let year = dateObj.getUTCFullYear();

        let numberValue = parseInt(number + "", frombase | 0);

        if (tobase < 2 || tobase > 36) {
          throw new Error("Base must be between 2 and 36");
        }

        let result;
        try {
          result = numberValue.toString(tobase | 0);
        } catch (error) {
          result = "error";
        }

        let link = `${carrotEnglishApi}/D${Math.round(year * 10000 + month * 100 + day)
          .toString(30)
          .toString(tobase | 0)}z${(201 + 10000).toString(30).toString(tobase | 0)}z${(parseInt(idxMember) + 10000)
          .toString(30)
          .toString(tobase | 0)}`;

        window.open(link, "_blank");
        return result;
      },

      goBackList() {
        router.go(-1);
      },
    });

    onMounted(() => {
      myClassInfoDetail.status();
    });

    return {
      myClassInfoDetail,
    };
  },
};
</script>
<style scoped></style>
